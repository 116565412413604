import React from 'react'
import { Button } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'

import './SsoButton.scss'

const SsoButton = ({ ssoAccountName, t }) => {
  return (
    <section className="client-login section client-sso">
      <p>{t('ssoLoginText', { ssoAccountName })}</p>

      {/* TODO: WILL CHANGE THE SSO BUTTON LINK TO getCognitoAuthUrl(clientName) whenever a client IdP is configured with Cognito */}
      <Button
        className="btn-primary sso-button btn btn--custom"
        onClick={() => (window.location.href = '/sso_login')}
      >
        {t('ssoLoginButtonText', { ssoAccountName })}
      </Button>
    </section>
  )
}

export default withTranslation(['sso'])(SsoButton)
