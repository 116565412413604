export default {
  translation: {
    authCheckingText:
      'Bitte warten Sie, während wir Ihre Zugangsdaten überprüfen...',
    backToLoginText: 'Zurück zur Anmeldung',
    changePasswordText: 'Ändern Sie Ihr Passwort',
    codeSentText: `Wir haben Ihnen einen Bestätigungscode per E-Mail zugesendet. Geben Sie diesen Code bitte unten ein, um Ihr Passwort zurückzusetzen.`,
    newPasswordCriteria:
      'Das Passwort muss mindestens 8 Zeichen lang sein und muss Großbuchstaben, numerische Zeichen und Symbolzeichen enthalten',
    emailLabel: 'your@email.com',
    emailText: 'Email',
    passwordText: `Passwort`,
    formFooterText: `Sie haben kein Konto? Registrieren`,
    getVerificationCodeText: 'Holen Sie sich einen Bestätigungscode',
    invalidEmailText: 'Ungültige E-Mail-Adresse',
    invalidCodeProvided:
      'Ungültiger Bestätigungscode angegeben. Bitte versuchen Sie es erneut.',
    loginText: 'Anmeldung',
    loadConfigError:
      'Konfiguration kann nicht geladen werden, bitte wenden Sie sich an das myadbox-Supportteam.',
    loadingConfig: 'Konfigurationsdaten werden geladen...',
    needFurtherStepText:
      'Sie müssen einen zusätzlichen Schritt unternehmen, bevor Sie sich anmelden: {{challengeName}}',
    newPasswordLabel: 'Neues Kennwort',
    oldPasswordLabel: 'Altes Passwort',
    passwordConfirmationText: `Passwort Bestätigung`,
    passwordNotMatchText: 'Passwörter müssen übereinstimmen',
    passwordLabel: 'Bitte geben Sie Ihr Passwort ein',
    passwordReenterLabel: `Bitte geben Sie Ihr neues Passwort erneut ein`,
    passwordResetRequired: `Klicken Sie unten auf "Passwort vergessen", um Ihr Passwort zurückzusetzen`,
    passwordUpdatedText: 'Ihr Passwort wurde erfolgreich aktuallisiert.',
    passwordShortText: 'Das Passwort muss mindestens 8 Zeichen lang sein.',
    passwordInvalidText:
      'Das Passwort erfordert eine Zahl, einen Großbuchstaben, einen Kleinbuchstaben und ein Sonderzeichen.',
    requireLoginText:
      'Bitte loggen Sie sich ein, bevor Sie Ihr Passwort ändern.',
    requiredText: 'Erforderlich',
    requiredEmailText: 'E-Mail-Adresse: (Pflichtfeld',
    requiredPasswordText: 'Passwort wird benötigt',
    resetPasswordText: 'Passwort vergessen?',
    resetText: 'Zurücksetzen',
    resetYourPasswordText: 'Setze dein Passwort zurück',
    sendVerificationCodeText: 'Bestätigungscode senden',
    setNewPasswordText: 'Legen Sie ein neues Passwort fest',
    setText: 'einstellen',
    updatePasswordText: 'Aktualisieren',
    verificationCodeLabel: 'Verifizierungs-Schlüssel',
    UnauthorisedResetError:
      'Ihr Passwort wurde von einem Administrator zurückgesetzt. Bitte verwenden Sie das vom Administrator bereitgestellte Passwort',
  },
  sso: {
    lexuscaLoginButtonText: 'Infostream / Infocourant',
    mazdaLoginButtonText: 'Mazda Portal',
    mbvadsuiteLoginButtonText: "Daimler's Federated Authentication",
    ssoLoginButtonText: '{{ ssoAccountName }} login',
    ssoLoginText: 'Or login with your {{ ssoAccountName }} account',
    toyotacaLoginButtonText: 'Infostream / Infocourant',
    toyotaLoginButtonText: 'TMCA network',
    vwadboxLoginButtonText: 'Single Sign On',
  },
  register: {
    registerText: `Sie haben kein Konto? Registrieren`,
  },
  supportDetails: {
    supportDetailsText: 'Support Details',
  },
}
