import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { ConfigContext } from '../../utils'

const GlobalStyles = () => {
  const config = useContext(ConfigContext)
  return (
    <Helmet>
      {config.account.faviconUrl && (
        <link id="favicon" rel="icon" href={config.account.faviconUrl} />
      )}
      {config.account.cognitoPageTitle && (
        <title>{config.account.cognitoPageTitle}</title>
      )}
      <style lang="scss">
        {`body.bgd--custom {
            background-image: url('${config.account.loginBackgroundImageUrl}');
            background-position: ${config.account.loginBackgroundPosition};
          }
          body.bgd::after {
            background-color: rgba(0, 0, 0, 0.${config.account.loginBackgroundOverlay});
          }
          .primary {
            color: ${config.account.color};
          }
          .ui.btn-primary {
            background-color: ${config.account.color};
          }
          .ui.btn-primary:hover, .ui.btn-primary:focus {
            background-color: ${config.account.color};
          }
          
          input:focus {
            border-bottom-color: ${config.account.color};
          }
          input:-webkit-autofill:focus {
            border: 0;
            border-bottom: 1px solid ${config.account.color};
          }
          .btn-link:hover {
            border-color: ${config.account.color};
          }
          .prompt-message {
            font-size: 1.2rem !important;
            font-weight: 400;
          }
        `}
      </style>
    </Helmet>
  )
}

export default GlobalStyles
