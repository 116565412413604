import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './en'
import fr from './fr'
import de from './de'

i18n.use(initReactI18next).init({
  resources: {
    en,
    fr,
    de
  },
  lng: 'en',

  keySeparator: false,

  interpolation: {
    escapeValue: false
  }
})

export default i18n
