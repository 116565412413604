import React, { useContext } from 'react'
import { withTranslation } from 'react-i18next'
import { ConfigContext } from '../../utils'

import './Register.scss'

const Register = ({ t }) => {
  const config = useContext(ConfigContext)

  return (
    config && (
      <a
        className='link-register'
        href={`/myregister?${config.account.shortName}`}
      >
        {t('registerText')}
      </a>
    )
  )
}

export default withTranslation(['register'])(Register)
