import React from 'react'
import Home from './components/home/Home'
import { useQuery } from '@apollo/react-hooks'
import GlobalStyles from './components/GlobalStyles'
import FETCH_CONFIG from './api/queries/fetchConfigQuery'
import { ConfigContext } from './utils'
import { useTranslation } from 'react-i18next'
import Logo from './assets/logo-sesimi.svg'
import CubeGrid from './assets/cube-grid.svg'
import { Loader, Dimmer } from 'semantic-ui-react'

import './App.scss'

const App = () => {
  const { loading, error, data } = useQuery(FETCH_CONFIG)
  const [t] = useTranslation()

  if (loading)
    return (
      <Dimmer active>
        <Loader className="pg-loader" content={t('loadingConfig')} />
      </Dimmer>
    )
  if (error) {
    console.error(error)
    return (
      <div className="load-config-error">
        <div className="branding-myadbox">
          <img className="logo" src={Logo} alt="Sesimi logo" />
        </div>
        {t('loadConfigError')}
        {/* <div className='cube-grid'>
          <img src={CubeGrid} alt='cube grid' />
        </div> */}
      </div>
    )
  }

  return (
    <ConfigContext.Provider value={data.config}>
      <Home />
      <GlobalStyles />
    </ConfigContext.Provider>
  )
}

export default App

// field :login_user_registration_enabled, Boolean, null: true
// field :login_hide_reset_password, Boolean, null: true
// field :sso_support, Boolean, null: true
// field :login_provide_support, Boolean, null: true
// field :login_languages, Types::Scalars::CustomJSON, null: true
// field :login_product_logo_url, String, null: true
// field :login_background_image_url, String, null: true
// field :login_background_overlay, Float, null: true
// field :login_background_position, String, null: true
// field :login_app_name, String, null: true
// field :sso_account_name, String, null: true
// field :custom_support_email, String, null: true
// field :custom_support_phone, String, null: true
