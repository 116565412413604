import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import i18n from 'i18next'
import SigninForm from '../signInForm/SigninForm'
import SendVerifyCodeForm from '../sendVerifyCodeForm/SendVerifyCodeForm'
import ResetPasswordForm from '../resetPasswordForm/ResetPasswordForm'
import ChangePasswordForm from '../changePasswordForm/ChangePasswordForm'
import { ConfigContext } from '../../utils'
import './Content.scss'

class Content extends Component {
  static contextType = ConfigContext

  render () {
    const {
      account: { loginLanguages },
    } = this.context
    const loginLanguagesArray = loginLanguages ? JSON.parse(loginLanguages) : [] //server should always send an array

    return (
      <div className='content'>
        <section className='lang-picker'>
          {loginLanguagesArray.map((langObj, index) => (
            <span>
              <button
                key={index}
                onClick={() => i18n.changeLanguage(langObj.locale)}
                className='btn-link'
              >
                {langObj.name}
              </button>
            </span>
          ))}
        </section>

        <Router>
          <Route exact path='/signin' component={SigninForm} />
          <Route
            path='/signin/send-verify-code/:email?'
            component={SendVerifyCodeForm}
          />
          <Route
            exact
            path='/signin/reset-password'
            component={ResetPasswordForm}
          />
          <Route
            exact
            path='/signin/change-password'
            component={ChangePasswordForm}
          />
        </Router>
      </div>
    )
  }
}

export default Content
