import React, { Component } from 'react'
import { Auth } from 'aws-amplify'
import { Button } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { configAmplify, ConfigContext } from '../../utils'

class ChangePasswordForm extends Component {
  static contextType = ConfigContext

  constructor(props) {
    super(props)
    this.state = {
      changingPassword: false,
      passwordChanged: false,
      authenticated: false,
      errMsg: null,
      user: null
    }
  }

  componentDidMount = () => {
    configAmplify(this.context.cognitoConfig, false)
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.setState({ user: user, authenticated: true })
      })
      .catch(error => {
        console.error(error)
        this.setState({ errMsg: error })
      })
  }

  handleChangePasswordClick = async values => {
    const { user } = this.state
    const { oldPassword, newPassword } = values
    const { history } = this.props
    this.setState({ changingPassword: true })

    try {
      await Auth.changePassword(user, oldPassword, newPassword)
      this.setState({ changingPassword: false, passwordChanged: true })
      setTimeout(() => {
        history.push('/signin')
      }, 2000)
    } catch (e) {
      this.setState({
        changingPassword: false,
        errMsg: e.message.replace(/username/i, 'email')
      })
    }
  }

  render() {
    const {
      changingPassword,
      passwordChanged,
      errMsg,
      authenticated
    } = this.state
    const { t } = this.props

    return authenticated ? (
      passwordChanged ? (
        <p className='prompt-message'>{t('passwordUpdatedText')}</p>
      ) : (
        <Formik
          initialValues={{ oldPassword: '', newPassword: '' }}
          validationSchema={Yup.object({
            oldPassword: Yup.string().required(t('requiredText')),
            newPassword: Yup.string()
              .required(t('requiredText'))
              .min(6, t('passwordShortText'))
          })}
          onSubmit={this.handleChangePasswordClick}
        >
          {props => (
            <Form key='changing-password' className='change-password-form'>
              <header className='header'>
                <h2 className='reset-password-header'>
                  {t('changePasswordText')}
                </h2>
              </header>

              <div className='form-field'>
                <label htmlFor='oldPassword'>{t('oldPasswordLabel')}</label>
                <Field
                  type='password'
                  name='oldPassword'
                  placeholder={t('oldPasswordLabel')}
                />
                <ErrorMessage
                  name='oldPassword'
                  render={msg => <div className='error-msg'>{msg}</div>}
                />
                {errMsg && <div className='submitted-error-msg'>{errMsg}</div>}
              </div>

              <div className='form-field'>
                <label htmlFor='newPassword'>{t('newPasswordLabel')}</label>
                <Field
                  type='password'
                  name='newPassword'
                  placeholder={t('newPasswordLabel')}
                />
                <ErrorMessage
                  name='newPassword'
                  render={msg => <div className='error-msg'>{msg}</div>}
                />
                <Link className='btn-link' to='/signin'>
                  {t('backToLoginText')}
                </Link>
              </div>

              <div className='btn-wrapper'>
                <Button
                  className='btn-primary btn'
                  type='submit'
                  loading={changingPassword}
                >
                  {t('updatePasswordText')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )
    ) : (
      <p className='prompt-message'>{t('requireLoginText')}</p>
    )
  }
}

export default withTranslation()(ChangePasswordForm)
