import React, { Component } from 'react'
import { Auth } from 'aws-amplify'
import { Button } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { configAmplify, ConfigContext } from '../../utils'
import PropTypes from 'prop-types'

class ResetPasswordForm extends Component {
  static contextType = ConfigContext

  constructor(props) {
    super(props)
    this.state = {
      changingPassword: false,
      passwordChanged: false,
      errMsg: null
    }
  }

  handleChangePasswordClick = async (values, admin = false) => {
    configAmplify(this.context.cognitoConfig, admin)
    const { code, password } = values
    const { history, t } = this.props

    this.setState({ changingPassword: true })

    try {
      await Auth.forgotPasswordSubmit(
        localStorage.getItem('reset-password-email'),
        code,
        password
      )
      this.setState({ changingPassword: false, passwordChanged: true })
      localStorage.clear('reset-password-email')
      setTimeout(() => {
        history.push('/signin')
      }, 2000)
    } catch (e) {
      if (!admin) {
        if (
          e.code &&
          (e.code.match(/Password/i) || e.code.match(/LimitExceeded/i))
        ) {
          this.setState({
            changingPassword: false,
            errMsg: e.message
          })
        } else this.handleChangePasswordClick(values, true)
      } else {
        const errMsg = e.message.match(
          /(invalid verification code)|(invalid code)/i
        )
          ? t('invalidCodeProvided')
          : e.message.replace(/username/i, 'email')
        this.setState({
          changingPassword: false,
          errMsg: errMsg
        })
      }
    }
  }

  render() {
    const { changingPassword, passwordChanged, errMsg } = this.state
    const { t } = this.props

    return passwordChanged ? (
      <p className='prompt-message'>{t('passwordUpdatedText')}</p>
    ) : (
      <Formik
        initialValues={{ code: '', password: '', passwordConfirmation: '' }}
        validationSchema={Yup.object({
          code: Yup.string().required(t('requiredText')),
          password: Yup.string()
            .required(t('requiredText'))
            .min(6, t('passwordShortText')),
          passwordConfirmation: Yup.string()
            .required(t('requiredText'))
            .oneOf([Yup.ref('password')], t('passwordNotMatchText'))
        })}
        onSubmit={(values, _actions) => this.handleChangePasswordClick(values)}
      >
        {props => (
          <Form className='reset-password-form' autoComplete='off'>
            <header className='header'>
              <h2 className='reset-password-header'>{t('codeSentText')}</h2>
            </header>
            <p className='new-password-criteria'>{t('newPasswordCriteria')}</p>
            <div className='form-field'>
              <label htmlFor='code'>{t('verificationCodeLabel')}</label>
              <Field
                type='text'
                name='code'
                placeholder={t('verificationCodeLabel')}
              />
              <ErrorMessage
                name='code'
                render={msg => <div className='error-msg'>{msg}</div>}
              />
            </div>
            <div className='form-field'>
              <label htmlFor='password'>{t('newPasswordLabel')}</label>
              <Field
                type='password'
                name='password'
                placeholder={t('setNewPasswordText')}
                autoComplete='new-password'
              />
              <ErrorMessage
                name='password'
                render={msg => <div className='error-msg'>{msg}</div>}
              />
            </div>
            <div className='form-field form-field-password-confirmation'>
              <label htmlFor='passwordConfirmation'>
                {t('passwordConfirmationText')}
              </label>
              <Field
                type='password'
                name='passwordConfirmation'
                placeholder={t('passwordReenterLabel')}
                autoComplete='new-password'
              />
              <div className='helper-wrapper'>
                <ErrorMessage
                  name='passwordConfirmation'
                  render={msg => <div className='error-msg'>{msg}</div>}
                />
                {errMsg && <div className='submitted-error-msg'>{errMsg}</div>}
              </div>
            </div>

            <div className='change-password-button'>
              <Button
                className='btn-primary btn'
                type='submit'
                loading={changingPassword}
              >
                {t('resetText')}
              </Button>
            </div>

            <Link className='btn-link' to='/signin'>
              {t('backToLoginText')}
            </Link>
          </Form>
        )}
      </Formik>
    )
  }
}

ResetPasswordForm.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  t: PropTypes.func
}

ResetPasswordForm.defaultProps = {
  location: {}
}

export default withTranslation()(ResetPasswordForm)
