import { gql } from 'apollo-boost'

const FETCH_CONFIG = gql`
  query fetchConfig {
    config {
      account {
        loginUserRegistrationEnabled
        loginProvideSupport
        loginLanguages
        ssoSupport
        backgroundColor
        color
        logoUrl
        name
        shortName
        productLogoUrl
        loginLogoImage
        loginProductLogoUrl
        loginBackgroundImageUrl
        loginBackgroundOverlay
        loginBackgroundPosition
        loginAppName
        ssoAccountName
        customSupportEmail
        customSupportPhone
        faviconUrl
        cognitoPageTitle
      }
      cognitoConfig {
        userPoolId
        appClientId
        adminUserPoolId
        adminAppClientId
      }
    }
  }
`

export default FETCH_CONFIG
