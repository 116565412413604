import React, { useContext, useEffect } from 'react'
import Logo from '../../assets/logo-sesimi.svg'
import CubeGrid from '../../assets/cube-grid.svg'
import Content from '../content/Content'
import './Home.css'
import { configAmplify, ConfigContext } from '../../utils'

const Home = () => {
  const config = useContext(ConfigContext)

  useEffect(() => {
    configAmplify(config.cognitoConfig, false)
  }, [config])

  return (
    <article className="app-wrapper">
      <div className="branding-myadbox">
        <img className="logo" src={Logo} alt="Sesimi logo" />
      </div>
      <section className="pg-wrap">
        <Content />
      </section>
      {/* <div className='cube-grid'>
        <img src={CubeGrid} alt='cube grid' />
      </div> */}
    </article>
  )
}

export default Home
