import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Button } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { configAmplify, ConfigContext } from "../../utils";

class SendVerifyCodeForm extends Component {
  static contextType = ConfigContext;

  constructor(props) {
    super(props);

    this.state = {
      sendingCode: false,
      codeSent: false,
      errMsg: null,
    };
  }

  componentDidMount() {
    localStorage.clear("reset-password-email");
    this.checkEmailParams();
  }

  handleSendCodeClick = async (values, admin = false) => {
    configAmplify(this.context.cognitoConfig, admin);
    const { email } = values;
    const { history } = this.props;
    this.setState({ sendingCode: true });
    try {
      await Auth.forgotPassword(email.toLowerCase());
      this.setState({ sendingCode: false, codeSent: true });
      localStorage.setItem("reset-password-email", email.toLowerCase());

      setTimeout(() => {
        history.push({ pathname: "/signin/reset-password" });
      }, 2000);
    } catch (e) {
      if (e.code === "NotAuthorizedException") {
        this.setState({
          sendingCode: false,
          errMsg: this.props.t("UnauthorisedResetError"),
        });
      } else if (!admin) {
        this.handleSendCodeClick(values, true);
      } else {
        this.setState({
          sendingCode: false,
          errMsg: e.message.replace(/username/i, "email"),
        });
      }
    }
  };

  checkEmailParams = () => {
    const email = this.props.match.params.email;
    if (email) {
      this.handleSendCodeClick({ email });
    }
  };

  render() {
    const { sendingCode, errMsg, codeSent } = this.state;
    const { t } = this.props;

    return codeSent ? (
      <p className="prompt-message">{t("codeSentText")}</p>
    ) : (
      <Formik
        initialValues={{ email: "" }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(t("invalidEmailText"))
            .required(t("requiredEmailText")),
        })}
        onSubmit={(values, _actions) => this.handleSendCodeClick(values)}
      >
        {(props) => (
          <Form className="send-verify-form">
            <header className="header">
              <h2 className="reset-password-header">
                {t("getVerificationCodeText")}
              </h2>
            </header>
            <div className="form-field">
              <label htmlFor="email">{t("emailText")}</label>
              <Field
                className="field"
                type="email"
                name="email"
                placeholder={t("emailLabel")}
              />
              <div className="helper-wrapper">
                <ErrorMessage
                  name="email"
                  render={(msg) => <div className="error-msg">{msg}</div>}
                />
                {errMsg && <div className="submitted-error-msg">{errMsg}</div>}
              </div>
              <Link className="btn-link" to="/signin">
                {t("backToLoginText")}
              </Link>
            </div>
            <div className="btn-wrapper">
              <Button
                className="btn btn-primary"
                type="submit"
                loading={sendingCode}
              >
                {t("sendVerificationCodeText")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withTranslation()(SendVerifyCodeForm);
