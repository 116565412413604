import React, { Component, Fragment } from 'react'
import { Button, Loader } from 'semantic-ui-react'
import { Auth } from 'aws-amplify'
import axios from 'axios'
import { withTranslation } from 'react-i18next'
import * as Sentry from '@sentry/browser'
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import SsoButton from '../../components/ssoButton/SsoButton'
import Register from '../../components/register/Register'
import SupportDetails from '../../components/supportDetails/SupportDetails'
import { configAmplify, ConfigContext } from '../../utils'

class SigninForm extends Component {
  static contextType = ConfigContext

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      newPassword: '',
      authChecking: true,
      errMsg: null,
      tempUser: null,
    }
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search)
    if (values && values.logout) {
      Auth.signOut()
      configAmplify(this.context.cognitoConfig, true)
      Auth.signOut()
        .then(() => this.setState({ authChecking: false }))
        .catch((err) => this.reportError(err, false))
      localStorage.clear()
    } else {
      this.checkUserSession()
    }
  }

  handleNewPasswordSubmit = async (values, admin = false) => {
    configAmplify(this.context.cognitoConfig, admin)
    const { tempUser } = this.state
    const { newPassword } = values
    this.setState({ loading: true, errMsg: null })

    try {
      await Auth.completeNewPassword(tempUser, newPassword)
      this.checkUserSession()
    } catch (err) {
      if (!admin) {
        this.handleNewPasswordSubmit(values, true)
      } else this.reportError(err, true)
    }
  }

  handleSubmit = async (values, admin = false) => {
    configAmplify(this.context.cognitoConfig, admin)
    const { email, password } = values
    const { t } = this.props
    this.setState({ loading: true, errMsg: null })
    try {
      const user = await Auth.signIn(email.toLowerCase(), password)
      if (user) {
        if (user.challengeName) {
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.setState({ loading: false, tempUser: user })
          } else {
            this.reportError(
              t('needFurtherStepText', { challengeName: user.challengeName }),
              true
            )
          }
        } else this.checkUserSession()
      }
    } catch (err) {
      if (!admin) {
        this.handleSubmit(values, true)
      } else this.reportError(err, true)
    }
  }

  checkUserSession = (admin = false) => {
    configAmplify(this.context.cognitoConfig, admin)
    Auth.currentSession()
      .then((cognitoSession) => {
        this.setState({ loading: false, authChecking: true })
        axios
          .post(
            '/user_sessions',
            {
              cognitoLoginRequest: true,
              ...cognitoSession,
            },
            {
              headers: {
                Accept: 'application/json',
              },
            }
          )
          .then((res) => {
            if (res.data && res.data.redirect_url)
              window.location.replace(res.data.redirect_url)
            else if (res.data && res.data.error) {
              this.reportError(res.data.error, true)
            } else window.location.replace('/dashboard')
          })
          .catch((err) => this.reportError(err, true))
      })
      .catch((err) => {
        if (!admin) {
          this.checkUserSession(true)
        } else this.reportError(err)
      })
  }

  reportError = (err, showErrMsg = false) => {
    const { t } = this.props
    if (err) {
      let message = err.message || err
      if (message === 'Password reset required for the user') {
        message = t('passwordResetRequired')
      }
      console.log(message)
      Sentry.captureMessage(message)
      this.setState({
        loading: false,
        authChecking: false,
        errMsg: showErrMsg ? message.replace(/username/i, 'email') : null,
      })
    } else {
      this.setState({ authChecking: false })
    }
  }

  renderSignInForm = () => {
    const { loading, errMsg } = this.state
    const { t } = this.props
    const config = this.context
    const {
      loginUserRegistrationEnabled,
      loginProvideSupport,
      ssoSupport,
      ssoAccountName,
      customSupportEmail,
      customSupportPhone,
    } = config.account

    return (
      <Fragment>
        <Formik
          key="sign-in-form"
          initialValues={{ email: '', password: '' }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email(t('invalidEmailText'))
              .required(t('requiredEmailText')),
            password: Yup.string().required(t('requiredPasswordText')),
          })}
          onSubmit={(values, _actions) => this.handleSubmit(values)}
        >
          {(props) => (
            <Form className="sign-in-form">
              <header className="login-header">
                <span className="client-app-name">
                  <img
                    className="logo"
                    src={config && config.account.loginProductLogoUrl}
                    alt="Sesimi logo"
                  />
                </span>
                <span className="client-branding fadein">
                  <img
                    className="logo"
                    src={config && config.account.loginLogoImage}
                    alt={`Branding for ${config.account.name}`}
                  />
                </span>
              </header>
              <div className="form-field">
                <label htmlFor="email">{t('emailText')}</label>
                <Field
                  id="email"
                  type="email"
                  name="email"
                  placeholder={t('emailLabel')}
                />
                <ErrorMessage
                  name="email"
                  render={(msg) => <div className="error-msg">{msg}</div>}
                />
              </div>

              <div className="form-field login-pwd-field">
                <label htmlFor="password">{t('passwordText')}</label>
                <Field
                  className="field"
                  type="password"
                  name="password"
                  placeholder={t('passwordLabel')}
                />
                <div className="helper-wrapper">
                  <ErrorMessage
                    name="password"
                    render={(msg) => <div className="error-msg">{msg}</div>}
                  />
                  {errMsg && (
                    <div className="submitted-error-msg login-pwd-error-msg">
                      {errMsg}
                    </div>
                  )}

                  <Link
                    className="reset-password btn-link"
                    to="/signin/send-verify-code"
                  >
                    {t('resetPasswordText')}
                  </Link>
                </div>
              </div>
              {/* {errMsg && <div className='submitted-error-msg'>{errMsg}</div>} */}
              <div className="btn-wrapper">
                <Button
                  className="btn btn-primary"
                  type="submit"
                  loading={loading}
                >
                  {t('loginText')}
                </Button>
              </div>

              {/* THIS IS A VARIABLE */}
              {/* REGISTRATION AREA */}

              {/* <section className="helper-links"> */}
              {/* <div>
                  <Link className='reset-password btn-link' to='/signin/send-verify-code'>
                    {t('resetPasswordText')}
                  </Link>
                </div> */}
              {/* <div> */}
              {/* <Link className='reset-password btn-link' to="/signin/send-verify-code">
                    {t('registerText')}
                  </Link> */}
              {/* </div> */}
              {/* </section> */}
              {/* <div className="back-login">
                
              </div> */}

              {/* IF STATEMENT - CLIENT VARIABLE */}

              {/* <footer className="form-footer">
                <Link to="/privacy-policy">
                  Privacy Policy
                </Link>
                <Link to="/terms-conditions">
                  Terms and conditions
                </Link>
                <Link to="/gdpr">
                  GDPR Compliance
                </Link>
              </footer> */}
            </Form>
          )}
        </Formik>
        {ssoSupport && <SsoButton ssoAccountName={ssoAccountName} />}
        {loginProvideSupport && (
          <SupportDetails
            customSupportEmail={customSupportEmail}
            customSupportPhone={customSupportPhone}
          />
        )}
        {loginUserRegistrationEnabled && <Register />}
      </Fragment>
    )
  }

  renderSetNewPasswordForm = () => {
    const { loading, errMsg } = this.state
    const { t } = this.props

    return (
      <Formik
        key="set-new-password-form"
        initialValues={{ newPassword: '', passwordConfirmation: '' }}
        validationSchema={Yup.object({
          newPassword: Yup.string()
            .required(t('requiredText'))
            .min(6, t('passwordShortText')),
          passwordConfirmation: Yup.string()
            .required(t('requiredText'))
            .oneOf([Yup.ref('newPassword')], t('passwordNotMatchText')),
        })}
        onSubmit={(values, _actions) => this.handleNewPasswordSubmit(values)}
      >
        {(props) => (
          <Form
            className="set-new-password-form"
            onChange={() => {
              this.setState({
                errMsg: null,
              })
            }}
          >
            <header className="header">
              <h2 className="reset-password-header">
                {t('setNewPasswordText')}
              </h2>
            </header>
            <div className="form-field">
              <label htmlFor="newPassword">{t('newPasswordLabel')}</label>
              <Field
                className="field"
                type="password"
                name="newPassword"
                placeholder={t('passwordLabel')}
                autoComplete="new-password"
              />
              <ErrorMessage
                name="newPassword"
                render={(msg) => <div className="error-msg">{msg}</div>}
              />
            </div>
            <div className="form-field">
              <label htmlFor="passwordConfirmation">
                {t('passwordConfirmationText')}
              </label>
              <Field
                type="password"
                name="passwordConfirmation"
                placeholder={t('passwordLabel')}
                autoComplete="new-password"
              />
              <ErrorMessage
                name="passwordConfirmation"
                render={(msg) => <div className="error-msg">{msg}</div>}
              />
              {errMsg && <div className="submitted-error-msg">{errMsg}</div>}
              {/* BACK TO LOGIN - removed as it doesn't make sense to have here */}
              {/* <Link className='btn-link' to='/signin'>
                {t('backToLoginText')}
              </Link> */}
            </div>

            <div className="btn-wrapper">
              <Button
                className="btn btn-primary"
                type="submit"
                loading={loading}
              >
                {t('setText')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    )
  }

  render() {
    const { authChecking, tempUser } = this.state
    const { t } = this.props

    return authChecking ? (
      <div className="auth-loader">
        <Loader className="prompt-message" content={t('authCheckingText')} />
      </div>
    ) : tempUser ? (
      this.renderSetNewPasswordForm()
    ) : (
      this.renderSignInForm()
    )
  }
}

export default withTranslation()(SigninForm)
