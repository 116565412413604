export default {
  translation: {
    authCheckingText: `Veuillez patienter pendant que nous vérifions vos informations d'identification`,
    backToLoginText: `Retour connexion`,
    changePasswordText: `Changez votre mot de passe`,
    codeSentText: `Nous vous avons envoyé un code de vérification, veuillez saisir ce code ci-dessous pour réinitialiser votre mot de passe.`,
    newPasswordCriteria: `Le mot de passe doit comporter au moins 8 caractères et doit contenir des majuscules, des chiffres et des symboles.`,
    emailLabel: "your@email.com",
    emailText: "Courriel",
    passwordText: `Mot De Passe`,
    formFooterText: `Vous n'avez pas de compte? S'inscrire`,
    getVerificationCodeText: `Obtenez un code de vérification`,
    invalidEmailText: `Adresse e-mail invalide`,
    invalidCodeProvided: `Code de vérification non valide fourni, veuillez réessayer.`,
    loginText: "Connexion",
    loadConfigError: `Impossible de charger la configuration, veuillez contacter l'équipe d'assistance myadbox.`,
    loadingConfig: `Chargement...`,
    needFurtherStepText:
      "Vous devez faire une étape supplémentaire avant de vous connecter: {{challengeName}}",
    newPasswordLabel: `Nouveau mot de passe`,
    oldPasswordLabel: `Ancien mot de passe`,
    passwordConfirmationText: `Confirmation mot de passe`,
    passwordNotMatchText: `Les mots de passe doivent correspondre`,
    passwordLabel: "Tapez votre mot de passe",
    passwordReenterLabel: `Veuillez ressaisir votre nouveau mot de passe`,
    passwordResetRequired: `Cliquez sur 'mot de passe oublié' ci-dessous pour réinitialiser votre mot de passe`,
    passwordUpdatedText: `Votre mot de passe a été modifié avec succès.`,
    passwordShortText: `Le mot de passe doit comporter au moins huit caractères`,
    passwordInvalidText:
      "Le mot de passe nécessite un chiffre, une lettre majuscule, une lettre minuscule et un caractère spécial.",
    requireLoginText: "Connectez-vous avant de changer votre mot de passe",
    requiredText: "Obligatoire",
    requiredEmailText: `Adresse e-mail est nécessaire`,
    requiredPasswordText: `Mot de passe requis`,
    resetPasswordText: `Réinitialisez votre mot de passe`,
    resetText: `Réinitialiser`,
    resetYourPasswordText: `Réinitialisez votre mot de passe`,
    sendVerificationCodeText: `Envoyer le code de vérification`,
    setNewPasswordText: `Définissez un nouveau mot de passe`,
    setText: `Ensemble`,
    updatePasswordText: `Mise à jour`,
    verificationCodeLabel: `Code de vérification`,
    UnauthorisedResetError:
      "Votre mot de passe a été réinitialisé par un administrateur. Veuillez utiliser le mot de passe fourni par l'administrateur",
  },
  sso: {
    lexuscaLoginButtonText: "Infostream / Infocourant",
    mazdaLoginButtonText: "Mazda Portal",
    mbvadsuiteLoginButtonText: "Daimler's Federated Authentication",
    ssoLoginButtonText: "Connexion de {{ ssoAccountName }}",
    ssoLoginText: "Connectez-vous avec votre compte {{ ssoAccountName }}",
    toyotacaLoginButtonText: "Infostream / Infocourant",
    toyotaLoginButtonText: "TMCA network",
    vwadboxLoginButtonText: "Single Sign On",
  },
  register: {
    registerText: `Enregistrez un compte`,
  },
  supportDetails: {
    supportDetailsText: "Détails du support",
  },
};
