export default {
  translation: {
    authCheckingText: "Please wait whilst we check your credentials...",
    backToLoginText: "Back to login",
    changePasswordText: "Change your password",
    codeSentText: `We've emailed you a verification code, please enter this code below to reset your password.`,
    newPasswordCriteria:
      "Password must be a minimum of 8 characters & must contain uppercase, numeric & symbol characters.",
    emailLabel: "your@email.com",
    emailText: "Email",
    passwordText: `Password`,
    formFooterText: `Don't have an account? Register`,
    getVerificationCodeText: "Get a verification code",
    invalidEmailText: "Invalid email address",
    invalidCodeProvided:
      "Invalid verification code provided, please try again.",
    loginText: "Login",
    loadConfigError:
      "Cannot load configuration, please contact myadbox support team.",
    loadingConfig: "Loading Config Data...",
    needFurtherStepText:
      "You need to take an extra step before login: {{challengeName}}",
    newPasswordLabel: "New Password",
    oldPasswordLabel: "Old Password",
    passwordConfirmationText: `Password Confirmation`,
    passwordNotMatchText: "Passwords must match",
    passwordLabel: "Please enter your password",
    passwordReenterLabel: `Please reenter your new password`,
    passwordResetRequired: `Click on 'forgot password' below to reset your password`,
    passwordUpdatedText: "Your password has been updated successfully.",
    passwordShortText: "Password must be a minimum of 8 characters.",
    passwordInvalidText:
      "Password requires a number, uppercase letter, lowercase letter & special character.",
    requireLoginText: "Please login before changing your password.",
    requiredText: "Required",
    requiredEmailText: "Email address required",
    requiredPasswordText: "Password is required",
    resetPasswordText: "Forgot password?",
    resetText: "Reset",
    resetYourPasswordText: "Reset your password",
    sendVerificationCodeText: "Send verification code",
    setNewPasswordText: "Set a new password",
    setText: "Set",
    updatePasswordText: "Update",
    verificationCodeLabel: "Verification Code",
    UnauthorisedResetError:
      "Your password has been reset by an administrator. Please use the password provided by the administrator",
  },
  sso: {
    lexuscaLoginButtonText: "Infostream / Infocourant",
    mazdaLoginButtonText: "Mazda Portal",
    mbvadsuiteLoginButtonText: "Daimler's Federated Authentication",
    ssoLoginButtonText: "{{ ssoAccountName }} login",
    ssoLoginText: "Or login with your {{ ssoAccountName }} account",
    toyotacaLoginButtonText: "Infostream / Infocourant",
    toyotaLoginButtonText: "TMCA network",
    vwadboxLoginButtonText: "Single Sign On",
  },
  register: {
    registerText: `Don't have an account? Register`,
  },
  supportDetails: {
    supportDetailsText: "Support Details",
  },
};
