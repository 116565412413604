import { polyfillLoader } from 'polyfill-io-feature-detection'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import './locales'
import * as serviceWorker from './serviceWorker'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from '@apollo/react-hooks'
import * as Sentry from '@sentry/browser'
import ReactGA from 'react-ga'

const { REACT_APP_STAGE } = process.env || 'staging'
if (REACT_APP_STAGE !== 'development') {
  Sentry.init({
    dsn: 'https://5c431309aaec453c81c1d64963d1d77f@sentry.io/1864511',
    environment: REACT_APP_STAGE
  })
  if (REACT_APP_STAGE === 'production') {
    ReactGA.initialize('UA-55482051-1')
  } else if (REACT_APP_STAGE === 'uat') {
    ReactGA.initialize('UA-55482051-4')
  } else if (REACT_APP_STAGE === 'staging') {
    ReactGA.initialize('UA-55482051-3')
  }  
  ReactGA.pageview(window.location.pathname + window.location.search)
}

const client = new ApolloClient({
  uri: `${window.location.origin}/graphql` //double check that is safe?
})

// This function load polyfills only if needed. By default it uses polyfill.io
polyfillLoader({
  "features": "Promise,fetch",
  "onCompleted": main
})


function main() {
	ReactDOM.render(
	  <ApolloProvider client={client}>
	    <App />
	  </ApolloProvider>,
	  document.getElementById('root')
	)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
