import Amplify from 'aws-amplify'
import { createContext } from 'react'

const getDeepComponent = (wrapper, componentName) => {
  return wrapper.find(componentName)
}

const getClientName = () => {
  return window.location.hostname && window.location.hostname.split('.')[0]
}

const getClientDomain = () => {
  return window.location.origin
}

const getCognitoAuthUrl = providerName => {
  const {
    REACT_APP_USER_POOL_CLIENT_ID,
    REACT_APP_COGNITO_DOMAIN,
    REACT_APP_COGNITO_AUTH_PATH,
    REACT_APP_COGNITO_REDIRECT_URL,
    REACT_APP_COGNITO_SCOPE
  } = process.env

  return `${REACT_APP_COGNITO_DOMAIN}${REACT_APP_COGNITO_AUTH_PATH}?identity_provider=${providerName}&redirect_uri=${REACT_APP_COGNITO_REDIRECT_URL}&response_type=CODE&client_id=${REACT_APP_USER_POOL_CLIENT_ID}&scope=${REACT_APP_COGNITO_SCOPE}`
}

const ssoClients = [
  'lexusca',
  'mazda',
  'mbvadsuite',
  'toyota',
  'toyotaca',
  'vwadbox'
]

const registerClients = ['toyota']

const configAmplify = (cognitoConfig, admin = false) => {
  Amplify.configure({
    Auth: {
      region: process.env.REACT_APP_REGION,
      userPoolId: admin
        ? cognitoConfig.adminUserPoolId
        : cognitoConfig.userPoolId,
      userPoolWebClientId: admin
        ? cognitoConfig.adminAppClientId
        : cognitoConfig.appClientId,
      clientMetadata: { domain: getClientDomain() },
      oauth: {
        domain: process.env.REACT_APP_COGNITO_DOMAIN.replace('https://', ''),
        scope: [process.env.REACT_APP_COGNITO_SCOPE],
        redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_URL,
        responseType: 'code'
      }
    }
  })
}

const ConfigContext = createContext({})

export {
  getDeepComponent,
  getClientName,
  getClientDomain,
  getCognitoAuthUrl,
  ssoClients,
  registerClients,
  configAmplify,
  ConfigContext
}
