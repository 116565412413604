import React from 'react'
import { withTranslation } from 'react-i18next'

import './SupportDetails.scss'

const SupportDetails = ({ t, customSupportEmail, customSupportPhone }) => {
  const mailTo = `mailto:${customSupportEmail}`
  const tel = `tel:${customSupportPhone}`
  return (
    <section className='support-details client-support section'>
      <h3>{t('supportDetailsText')}</h3>
      <div className="support-method">
        <a href={mailTo}><i className="icon-email"></i> <span className="btn-link">{customSupportEmail}</span></a>
      </div>
      <div className="support-method">
        <a href={tel}><i className="icon-phone"></i> <span className="btn-link">{customSupportPhone}</span></a>
      </div>
    </section>
  )
}

export default withTranslation(['supportDetails'])(SupportDetails)
